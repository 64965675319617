@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import '~antd/dist/antd.css';

@font-face {
  font-family:"Comic Sans MS";
  src: url("./fonts/comic-sans-ms.ttf") 
}

.App {
  /* text-align: center; */
  background: rgb(0,176,255,1);
  /* background: radial-gradient(circle, rgba(255,247,213,1) 0%, rgba(0,176,255,1) 100%); */ 
  
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-card{
  border-radius: 16px !important;
  margin-bottom: 16px !important;
}

.ant-collapse{
  border-top-left-radius: 16px  !important;
  border-top-right-radius: 16px  !important;
  border-bottom-right-radius: 16px  !important;
  border-bottom-left-radius: 16px  !important;
}
.ant-btn{
  border-radius: 8px !important;
}

.ant-page-header{
  border-radius: 16px;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: "#333"
}

* {
  font-size: 100%;
  font-family: 'Source Sans Pro', sans-serif;
}
p, table {
  font-size: 1.15em;
}
.ant-form-item-label > label {
  font-size: 1.15em !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.csm {
  font-family: "Comic Sans MS", "Comic Sans", "cursive";
  zoom: 1.1;
}